.selectize-control.plugin-drag_drop.multi > .selectize-input.dragging {
  overflow: visible;
}
.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  box-shadow: inset 0 0 12px 4px #fff;
}
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: "!";
  visibility: hidden;
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header {
  position: relative;
  padding: 10px 8px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  border-radius: 3px 3px 0 0;
}
.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close {
  position: absolute;
  right: 8px;
  top: 50%;
  color: #303030;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
  text-decoration: none;
}
.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close:hover {
  color: black;
}

.selectize-dropdown.plugin-optgroup_columns .selectize-dropdown-content {
  display: flex;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0 none;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0 none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none;
}

.selectize-control.plugin-remove_button .item {
  display: inline-flex;
  align-items: center;
  padding-right: 0 !important;
}
.selectize-control.plugin-remove_button .item .remove {
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 6px;
  border-left: 1px solid #0073bb;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
  margin-left: 6px;
}
.selectize-control.plugin-remove_button .item .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}
.selectize-control.plugin-remove_button .item.active .remove {
  border-left-color: #00578d;
}
.selectize-control.plugin-remove_button .disabled .item .remove:hover {
  background: none;
}
.selectize-control.plugin-remove_button .disabled .item .remove {
  border-left-color: #aaaaaa;
}

.selectize-control.plugin-clear_button .clear {
  text-decoration: none;
  display: flex;
  position: absolute;
  height: 100%;
  width: 25px;
  top: 0;
  right: calc(8px - 6px);
  color: rgb(0, 0, 0);
  opacity: 0.4;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 1;
  font-size: 21px;
  justify-content: center;
  align-items: center;
}
.selectize-control.plugin-clear_button .clear:hover {
  opacity: 1;
}
.selectize-control.plugin-clear_button.single .clear {
  right: calc(8px - 6px + 1.5rem);
}

.selectize-dropdown.plugin-auto_position.selectize-position-top {
  border-top: 1px solid #d0d0d0;
  border-bottom: 0 none;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.18);
}

.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active {
  border-radius: 0 0 3px 3px;
  border-top: 0 none;
}
.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active::before {
  top: 0;
  bottom: unset;
}

.selectize-control {
  position: relative;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
  color: #303030;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  font-smoothing: inherit;
}

.selectize-input,
.selectize-control.single .selectize-input.input-active {
  background: #fff;
  cursor: text;
  display: inline-block;
}

.selectize-input {
  border: 1px solid #d0d0d0;
  padding: 8px 8px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.selectize-control.multi .selectize-input.has-items {
  padding: calc(
      8px - 2px - 1px
    ) 8px calc(
      8px - 2px - 3px -
        1px
    );
}
.selectize-input.full {
  background-color: #fff;
}
.selectize-input.disabled, .selectize-input.disabled * {
  cursor: default !important;
}
.selectize-input.focus {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
}
.selectize-input.dropdown-active {
  border-radius: 3px 3px 0 0;
}
.selectize-input > * {
  vertical-align: baseline;
  display: inline-block;
  zoom: 1;
}
.selectize-control.multi .selectize-input > div {
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
  background: #1da7ee;
  color: #fff;
  border: 1px solid #0073bb;
}
.selectize-control.multi .selectize-input > div.active {
  background: #92c836;
  color: #fff;
  border: 1px solid #00578d;
}
.selectize-control.multi .selectize-input.disabled > div, .selectize-control.multi .selectize-input.disabled > div.active {
  color: white;
  background: #d2d2d2;
  border: 1px solid #aaaaaa;
}
.selectize-input > input {
  display: inline-block !important;
  padding: 0 !important;
  min-height: 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  margin: 0 !important;
  text-indent: 0 !important;
  border: 0 none !important;
  background: none !important;
  line-height: inherit !important;
  user-select: auto !important;
  box-shadow: none !important;
}
.selectize-input > input::-ms-clear {
  display: none;
}
.selectize-input > input:focus {
  outline: none !important;
}
.selectize-input > input[placeholder] {
  box-sizing: initial;
}
.selectize-input.has-items > input {
  margin: 0 0px !important;
}

.selectize-input::after {
  content: " ";
  display: block;
  clear: left;
}

.selectize-input.dropdown-active::before {
  content: " ";
  display: block;
  position: absolute;
  background: #f0f0f0;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.selectize-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  border: 1px solid #d0d0d0;
  background: #fff;
  margin: -1px 0 0 0;
  border-top: 0 none;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
}
.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}
.selectize-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, 0.2);
  border-radius: 1px;
}
.selectize-dropdown .option,
.selectize-dropdown .optgroup-header,
.selectize-dropdown .no-results,
.selectize-dropdown .create {
  padding: 5px 8px;
}
.selectize-dropdown .option,
.selectize-dropdown [data-disabled],
.selectize-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: 0.5;
}
.selectize-dropdown [data-selectable].option {
  opacity: 1;
  cursor: pointer;
}
.selectize-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0 none;
}
.selectize-dropdown .optgroup-header {
  color: #303030;
  background: #fff;
  cursor: default;
}
.selectize-dropdown .active {
  background-color: #92c836;
  color: #fff;
}
.selectize-dropdown .active.create {
  color: #495c68;
}
.selectize-dropdown .selected {
  background-color: #92c836;
  color: #fff;
}
.selectize-dropdown .create {
  color: rgba(48, 48, 48, 0.5);
}
.selectize-dropdown .active:not(.selected) {
  background: #f5fafd;
  color: #495c68;
}

.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  overflow-scrolling: touch;
}

.selectize-dropdown-emptyoptionlabel {
  text-align: center;
}

.selectize-dropdown .spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 5px 8px;
}
.selectize-dropdown .spinner:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 3px;
  border-radius: 50%;
  border: 5px solid #d0d0d0;
  border-color: #d0d0d0 transparent #d0d0d0 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
  cursor: pointer;
}
.selectize-control.single .selectize-input.input-active, .selectize-control.single .selectize-input.input-active input:not(:read-only) {
  cursor: text;
}
.selectize-control.single .selectize-input:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #808080 transparent transparent transparent;
}
.selectize-control.single .selectize-input.dropdown-active:after {
  margin-top: -4px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #808080 transparent;
}

.selectize-control.rtl {
  text-align: right;
}
.selectize-control.rtl.single .selectize-input:after {
  left: 15px;
  right: auto;
}
.selectize-control.rtl .selectize-input > input {
  margin: 0 4px 0 -2px !important;
}

.selectize-control .selectize-input.disabled {
  opacity: 0.5;
  background-color: #fafafa;
}

.selectize-control.multi .selectize-input.has-items {
  padding-left: 5px;
  padding-right: 5px;
}
.selectize-control.multi .selectize-input.disabled [data-value] {
  color: #999;
  text-shadow: none;
  background: none;
  box-shadow: none;
}
.selectize-control.multi .selectize-input.disabled [data-value],
.selectize-control.multi .selectize-input.disabled [data-value] .remove {
  border-color: #e6e6e6;
}
.selectize-control.multi .selectize-input.disabled [data-value] .remove {
  background: none;
}
.selectize-control.multi .selectize-input [data-value] {
  text-shadow: 0 1px 0 rgba(0, 51, 83, 0.3);
  border-radius: 3px;
  background-color: #1b9dec;
  background-image: linear-gradient(to bottom, #1da7ee, #178ee9);
  background-repeat: repeat-x;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), inset 0 1px rgba(255, 255, 255, 0.03);
}
.selectize-control.multi .selectize-input [data-value].active {
  background-color: #0085d4;
  background-image: linear-gradient(to bottom, #008fd8, #0075cf);
  background-repeat: repeat-x;
}
.selectize-control.single .selectize-input {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 rgba(255, 255, 255, 0.8);
  background-color: #f9f9f9;
  background-image: linear-gradient(to bottom, #fefefe, #f2f2f2);
  background-repeat: repeat-x;
}

.selectize-control.single .selectize-input,
.selectize-dropdown.single {
  border-color: #b8b8b8;
}

.selectize-dropdown .optgroup-header {
  padding-top: 7px;
  font-weight: bold;
  font-size: 0.85em;
}
.selectize-dropdown .optgroup {
  border-top: 1px solid #f0f0f0;
}
.selectize-dropdown .optgroup:first-child {
  border-top: 0 none;
}

.selectize-dropdown.plugin-auto_position.selectize-position-top {
  border-top: 1px solid #d0d0d0;
  border-bottom: 0 none;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.18);
}

.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active {
  border-radius: 0 0 3px 3px;
  border-top: 0 none;
}
.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active::before {
  top: 0;
  bottom: unset;
}

.selectize-control.plugin-clear_button .clear {
  text-decoration: none;
  display: flex;
  position: absolute;
  height: 100%;
  width: 25px;
  top: 0;
  right: calc(8px - 6px);
  color: rgb(0, 0, 0);
  opacity: 0.4;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 1;
  font-size: 21px;
  justify-content: center;
  align-items: center;
}
.selectize-control.plugin-clear_button .clear:hover {
  opacity: 1;
}
.selectize-control.plugin-clear_button.single .clear {
  right: calc(8px - 6px + 1.5rem);
}

.selectize-control.plugin-drag_drop.multi > .selectize-input.dragging {
  overflow: visible;
}
.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  box-shadow: inset 0 0 12px 4px #fff;
}
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: "!";
  visibility: hidden;
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header {
  position: relative;
  padding: 10px 8px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  border-radius: 3px 3px 0 0;
}
.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close {
  position: absolute;
  right: 8px;
  top: 50%;
  color: #303030;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
  text-decoration: none;
}
.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close:hover {
  color: black;
}

.selectize-dropdown.plugin-optgroup_columns .selectize-dropdown-content {
  display: flex;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0 none;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0 none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none;
}

.selectize-control.plugin-remove_button .item {
  display: inline-flex;
  align-items: center;
  padding-right: 0 !important;
}
.selectize-control.plugin-remove_button .item .remove {
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 6px;
  border-left: 1px solid #0073bb;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
  margin-left: 6px;
}
.selectize-control.plugin-remove_button .item .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}
.selectize-control.plugin-remove_button .item.active .remove {
  border-left-color: #00578d;
}
.selectize-control.plugin-remove_button .disabled .item .remove:hover {
  background: none;
}
.selectize-control.plugin-remove_button .disabled .item .remove {
  border-left-color: #aaaaaa;
}